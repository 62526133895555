import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    intervalId: 0,
    sidebarShow: 'responsive'
}

export const intervalSlice = createSlice({
  name: 'interval',
  initialState,
  reducers: {
    update: (state, action) => {
      state.intervalId = action.payload
    },
    set: (state, action) => {
      state.sidebarShow = action.payload
    }
  },
})

export const { update, set } = intervalSlice.actions

export default intervalSlice.reducer